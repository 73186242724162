import React from 'react';
import {
  makeStyles,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';

import Layout from '../components/layout';
import SEO from '../components/seo';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: '88vh',
  },
  affiliationInfo: {
    marginTop: 25,
  },
  gridItem: {
    paddingBottom: '16px !important',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '12px !important',
    },
  },
  divider: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export default function IndexPage() {
  const classes = useStyles();
  return (
    <Layout>
      <SEO title="Home" />
      <Grid container direction="column" wrap="nowrap" justify="space-around" alignContent="center" alignItems="center" spacing={8} className={classes.wrapper}>
        <Grid item key="1" md={12} className={classes.affiliationInfo}>
          <Typography variant="h3" component="h1" align="center" gutterBottom>
            Krzysztof Dobosz
          </Typography>
          <Typography variant="h4" component="h2" align="center" gutterBottom>
            Cognitive Scientist | Software Developer
          </Typography>
          <Typography variant="h5" component="h3" align="center" gutterBottom>
            Artificial Intelligence / Machine Learning Engineer
            <br />
            Full-Stack / Mobile / Game Developer
            <br />
            IT Researcher / IT Trainer
            <br />
            Linux Administrator
          </Typography>
          {/* <Typography variant="subtitle1" align="center" paragraph>
          </Typography> */}
        </Grid>
        <Grid item key="2" md={12}>
          <Grid container justify="center" spacing={10}>
            <Grid item key="1" xs={11} md={5} className={classes.gridItem}>
              <Typography variant="h5" component="h4" align="left" gutterBottom>
                Research Areas
              </Typography>
              <List dense>
                <ListItem>
                  <ListItemText
                    primary="Artificial Intelligence, Machine Learning"
                    secondary="Deep Learning, Meta Learning, Neural Networks, Support Vector Machine, Decision Trees, Neighbors-based Learning"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Neuroscience, Neuroinformatics"
                    secondary="Brain Function Modeling, Neurodynamics and Neuroimaging Analysis"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Data Science, Data Mining"
                    secondary="Data Analysis and Visualization, Signal Processing, Clusterization"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Visualization Techniques"
                    secondary="Multidimensional Scaling, Principal Component Analysis, Recurrence Plots, Fuzzy Symbolic Dynamics, Prototype Distance Plots etc."
                  />
                </ListItem>
              </List>
            </Grid>
            <Divider orientation="vertical" flexItem className={classes.divider} />
            <Grid item key="2" xs={11} md={5} className={classes.gridItem}>
              <Typography variant="h5" component="h4" align="left" gutterBottom>
                Development Stack
              </Typography>
              <List dense>
                <ListItem>
                  <ListItemText
                    primary="Web & Mobile Development"
                    secondary="
                      JavaScript/TypeScript, PHP, (X)HTML, CSS/SASS, MySQL, GraphQL, MongoDB,
                      Node.js, React, React Native, Gatsby (SSR), Axios, Material UI, Flow, ESLint (Airbnb),
                      Vanilla JS, SEO, Photoshop
                    "
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Static-typed Technologies"
                    secondary="C/C++, .NET/C# (Core, Mono), Unity"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Research Linked"
                    secondary="Matlab, Python (scikit-learn, SciPy, Matplotlib, Pandas etc.), TensorFlow, Keras, RapidMiner, GhostMiner, Weka"
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="OS Platforms"
                    secondary="Linux (LPI certification), Windows, Android, Raspberry Pi OS, Proxmox"
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}
